import React, { useMemo, useState } from 'react'
import { Box, Button, Center, Factory, IBoxProps, Image, Skeleton, Stack, Text, useTheme } from 'native-base'
import { useTranslation } from 'react-i18next'
import { CharlyIcon, MissingIndicator } from '@charlycares/ui'
import { useGetAngelTodosQuery } from '@charlycares/data-access'
import { AngelTodos, IdinLogo, useRouter } from '@charlycares/shared'
import { Linking, TouchableOpacity } from 'react-native'

const Touchable = Factory(TouchableOpacity)

type Props = IBoxProps & {
  hideTitle?: boolean
}

export default function AngelTodoList({ hideTitle, ...boxProps }: Props) {
  const { t } = useTranslation()
  const { navigate, didFocus } = useRouter()
  const { colors } = useTheme()
  const [showAll, setShowAll] = useState(false)
  const { data: todos, isLoading, refetch, isFetching, isUninitialized } = useGetAngelTodosQuery()

  const todoList = useMemo(() => {
    const data: { title: string; description: string; icon: React.ReactNode; onPress: () => void }[] = []

    todos?.forEach(todo => {
      if (!todo.completed) {
        switch (todo.item) {
          case AngelTodos.REFERENCE:
            data.push({
              title: t('todoAddReferences'),
              description: t('todoAddReferencesDescription'),
              icon: <CharlyIcon size={30} color={colors.gray[900]} name="icn-validated" />,
              onPress: () => navigate('ProfileAngelAddReference')
            })
            break
          case AngelTodos.BANK_ACCOUNT:
            data.push({
              title: t('todoAddBankAccount'),
              description: t('todoAddBankAccountDescription'),
              icon: <CharlyIcon size={30} color={colors.gray[900]} name="credit-card" />,
              onPress: () => navigate('ProfileAccount')
            })
            break
          case AngelTodos.HOURLY_RATES:
            data.push({
              title: t('todoHourlyRate'),
              description: t('todoHourlyRateDescription'),
              icon: <CharlyIcon size={28} color={colors.gray[900]} name="icn-payments" />,
              onPress: () => navigate('ProfilePersonalSettings')
            })
            break
          case AngelTodos.PREFERENCES:
            data.push({
              title: t('todoPreferences'),
              description: t('todoPreferencesDescription'),
              icon: <CharlyIcon size={30} color={colors.gray[900]} name="time" />,
              onPress: () => navigate('ProfileAngelPreferences')
            })
            break
          case AngelTodos.PROMO_VIDEO:
            data.push({
              title: t('todoPromovideo'),
              description: t('todoPromovideoDescription'),
              icon: <CharlyIcon size={30} color={colors.gray[900]} name="video-play" />,
              onPress: () => navigate('ProfileAccount')
            })
            break
          case AngelTodos.SHORT_BIO:
            data.push({
              title: t('todoShortBio'),
              description: t('todoShortBioDescription'),
              icon: <CharlyIcon size={30} color={colors.gray[900]} name="reading" />,
              onPress: () => navigate('ProfileAccount')
            })
            break
          case AngelTodos.PROFILE_DETAILS:
            data.push({
              title: t('todoProfileDetails'),
              description: t('todoProfileDetailsDescription'),
              icon: <CharlyIcon size={30} color={colors.gray[900]} name="settings" />,
              onPress: () => navigate('ProfileAccount')
            })
            break
          case AngelTodos.PROFILE_VERIFIED:
            data.push({
              title: t('todo.profileVerified'),
              description: t('todo.profileVerifiedDesc'),
              // @ts-ignore
              icon: <Image source={IdinLogo} w="34px" h="32px" />,
              onPress: () => todo.additional_data.url && Linking.openURL(todo.additional_data.url)
            })
            break
          case AngelTodos.PROFILE_PICTURE:
          case AngelTodos.PROFILE_PICTURE_VERIFIED:
            {
              const exist = data.find(item => item.title === t('todoprofilepic'))
              if (exist) break

              data.push({
                title: t('todoprofilepic'),
                description: t('todoprofilepicDescription'),
                icon: <CharlyIcon size={30} color={'gray.900'} name="icn-photo" />,
                onPress: () => navigate('AngelPhotoUpload')
              })
            }
            break
        }
      }
    })

    return data
  }, [colors, navigate, t, todos])

  if (!isLoading && !todoList.length) {
    return null
  }

  return (
    <Box w="100%" my="10px" {...boxProps}>
      {(!hideTitle || !isLoading) && (
        <Text mb="5px" fontSize="18px" fontWeight="600">
          {`${t('todoList')} ${todoList.length ? `(${todoList.length})` : ''}`}
        </Text>
      )}

      {isLoading && (
        <Stack space="5px">
          {Array.from({ length: 2 }).map((_, index) => (
            <Skeleton key={index} h="40px" w="100%" rounded="md" startColor="gray.100" />
          ))}
        </Stack>
      )}

      {!isLoading && (
        <Stack space="5px">
          {todoList.slice(0, showAll ? todoList.length : 3).map(({ title, description, icon, onPress }) => (
            <Touchable key={title} onPress={onPress} overflow="hidden" bg="gray.100" borderRadius="4px">
              <MissingIndicator
                h="100%"
                position="absolute"
                left={0}
                top={0}
                bottom={0}
                w="5px"
                bg="alert.danger.400"
              />

              <Box py="10px" px="15px" flexDir="row" alignItems="center">
                {icon}

                <Box ml="15px" flex={1}>
                  <Text fontSize="14px" fontWeight="600">
                    {title}
                  </Text>
                  <Text fontSize={'12px'} color="gray.800">
                    {description}
                  </Text>
                </Box>

                <CharlyIcon size={30} color={colors.gray[500]} name="icn-caret-right" />
              </Box>
            </Touchable>
          ))}
        </Stack>
      )}

      {todoList.length > 3 && (
        <Center mt="10px">
          <Button
            onPress={() => setShowAll(!showAll)}
            variant="text"
            _text={{
              color: 'gray.800',
              fontWeight: '600',
              fontSize: '13px'
            }}
            rightIcon={<CharlyIcon size={20} color={'gray.500'} name={`icn-nav-${showAll ? 'up' : 'down'}`} />}
          >
            {t(showAll ? 'todoListShowLess' : 'todoListShowMore')}
          </Button>
        </Center>
      )}
    </Box>
  )
}
