import React, { useMemo, useState } from 'react'
import { Avatar, Box, Button, Center, Heading, HStack, IPressableProps, Pressable, Text, VStack } from 'native-base'
import {
  AngelSummery,
  BookingServiceType,
  formatMoney,
  getDistance,
  getLastOnline,
  isWeb,
  useRouter
} from '@charlycares/shared'
import { Card, CharlyIcon } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { useToggleFavoriteAngelMutation } from '@charlycares/data-access'
import moment from 'moment'

type Props = IPressableProps & {
  angel: AngelSummery
  onSelect?: (angel: AngelSummery) => void
  isSelected?: boolean
  isSelectableInDetails?: boolean
  bookingServiceType?: BookingServiceType
}

const AngelCard = ({ angel, onSelect, isSelected, isSelectableInDetails, bookingServiceType, ...boxProps }: Props) => {
  const { t } = useTranslation()
  const { navigate } = useRouter()

  const [isFavorite, setIsFavorite] = useState(angel.is_liked || angel.is_favourite)

  const [toggleFavorite] = useToggleFavoriteAngelMutation()

  const forPetCareBooking = bookingServiceType === BookingServiceType.PET_CARE

  const icons = useMemo(() => {
    const data = [
      {
        icon: <CharlyIcon name="icn-location" size={'25px'} color="gray.900" />,
        label: getDistance(angel.distance_in_km * 1000 || angel.distance * 1000)
      }
    ]

    if (!forPetCareBooking) {
      data.push(
        {
          icon: <CharlyIcon name="icn-day" size={'25px'} color="gray.900" />,
          label: formatMoney(angel.day_rate_per_hour)
        },
        {
          icon: <CharlyIcon name="icn-night" size={'25px'} color="gray.900" />,
          label: formatMoney(angel.night_rate_per_hour)
        }
      )
    }

    if (!forPetCareBooking && angel.toddler_expertise) {
      data.push({
        icon: <CharlyIcon name="icn-baby-bottle" size={'25px'} color="gray.900" />,
        label: t('baby')
      })
    }

    const skills = [angel.first_aid, angel.tutor, angel.driving_license].filter(Boolean).length
    if (skills > 0 && !forPetCareBooking) {
      data.push({
        icon: (
          <Text fontSize="20px" lineHeight={'25px'}>
            +{skills}
          </Text>
        ),
        label: t('skills')
      })
    }

    return data
  }, [angel, t])

  return (
    <Pressable
      key={angel.obscured_id}
      onPress={() =>
        navigate('AngelProfile', `/booking-angel-profile`, {
          angelId: angel.obscured_id,
          selectable: isSelectableInDetails === undefined ? true : isSelectableInDetails
        })
      }
      {...boxProps}
    >
      <Card mt="0">
        <HStack space="10px" alignItems={'center'}>
          <Box>
            <Avatar size="80px" source={{ uri: angel.image }} />
            <CharlyIcon
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
              onPress={() => {
                toggleFavorite({ angel_id: angel.id, like: !isFavorite, angelObscuredId: angel.obscured_id })
                setIsFavorite(!isFavorite)
              }}
              name={isFavorite ? 'heart' : 'favorite-inactive'}
              color={isFavorite ? 'primary.400' : 'gray.500'}
              size={32}
              position="absolute"
              left={'-15px'}
              top={'-15px'}
            />
          </Box>

          <VStack flex={1}>
            <Heading fontSize="16px">
              {angel.first_name} ({angel.age})
            </Heading>

            {angel.available && (
              <HStack space="5px" mt="5px" alignItems="center">
                <Heading fontSize={'15px'}>{angel.average_rating?.toFixed(2)}</Heading>
                <CharlyIcon name="star" size={15} color={'gray.800'} />
                <Text fontSize={'12px'}>({angel.written_review_count})</Text>
              </HStack>
            )}

            {angel.available && (
              <HStack space={isWeb ? '40px' : '20px'} alignItems="center" justifyContent={'flex-start'} mt="10px">
                {icons.map(({ label, icon }, i) => (
                  <Center key={label + i}>
                    {icon}
                    <Text fontSize="12px" mt="5px">
                      {label}
                    </Text>
                  </Center>
                ))}
              </HStack>
            )}

            {!angel.available && (
              <VStack space="10px" mt="10px">
                <Box flex={1}>
                  <Text fontSize={16}>{t('angelNotAvailable')}</Text>
                  <Text fontSize={12} color="gray.700">
                    {t('angelCalendarIsBlocked')}
                  </Text>
                </Box>

                <HStack mt="10px" alignItems="center" space="20px">
                  <Button
                    onPress={() => navigate('BookDirect', '/book-direct', { angelId: angel.obscured_id })}
                    variant="text"
                    leftIcon={<CharlyIcon color="primary.400" name="icn-calendar" size={'32px'} />}
                  >
                    {t('calendar')}
                  </Button>

                  <Button
                    isDisabled={!angel.conversation_id}
                    onPress={() => navigate('ChatScreen', '/chat', { conversationId: angel.conversation_id })}
                    variant="text"
                    leftIcon={<CharlyIcon color="primary.400" name="icn-chat" size={'32px'} />}
                  >
                    {t('message')}
                  </Button>
                </HStack>
              </VStack>
            )}
          </VStack>

          <Box h="100%" ml="10px">
            {onSelect && angel.available ? (
              <CharlyIcon
                hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                onPress={() => {
                  requestAnimationFrame(() => {
                    onSelect(angel)
                  })
                }}
                name={isSelected ? 'btn-check' : 'btn-plus'}
                size={'30px'}
                color={isSelected ? 'secondary.400' : 'gray.500'}
              />
            ) : (
              <CharlyIcon name="icn-caret-right" size={'30px'} />
            )}
          </Box>
        </HStack>

        {angel.available && Boolean(angel.availabilities?.length || angel.last_seen_at) && (
          <Box mt="15px" flexDir="row" w="100%" justifyContent="space-between" alignItems="center">
            <Text fontSize="12px">
              {angel.last_seen_at ? `${t('lastOnline')}: ${getLastOnline(angel.last_seen_at)}` : ''}
            </Text>

            {!!angel.availabilities.length && (
              <HStack space="2px">
                {angel.availabilities?.map(({ morning, afternoon, evening, day_of_week }) => (
                  <Center
                    key={day_of_week}
                    bg="gray.300"
                    opacity={morning || afternoon || evening ? 1 : 0.3}
                    w="17px"
                    h="17px"
                  >
                    <Text textTransform="capitalize" fontSize={'12px'} lineHeight="17px">
                      {moment()
                        .day(day_of_week + 1)
                        .format('dddd')
                        .substring(0, 1)}
                    </Text>
                  </Center>
                ))}
              </HStack>
            )}
          </Box>
        )}
      </Card>
    </Pressable>
  )
}

export default AngelCard
