import React, { useEffect, useState } from 'react'
import { Card, ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import {
  BookingDate,
  BookingServiceType,
  BookingStates,
  flattenBookingDates,
  IBookingDetails,
  InvitationsStates,
  saveInCalendar,
  useRouter
} from '@charlycares/shared'
import {
  useAcceptInvitationMutation,
  useDeclineInvitationMutation,
  useGetBookingCancellationReasonsQuery,
  useGetBookingDetailsQuery,
  useGetUserQuery,
  useSendOfferMutation
} from '@charlycares/data-access'
import { Button, Center, HStack } from 'native-base'

import { BookingFamilyDetails } from '../family'
import { AddressDetails } from '../../profile'
import { BookingDates } from './common'
import { useAlert } from '../../../hooks'
import { isSingleBooking } from '../common/booking.utils'
import BookingMessage from '../family/common/BookingMessage'
import BookingRatesDetails from '../family/common/BookingRatesDetails'
import BookingServiceTypeInfo from '../common/BookingServiceTypeInfo'
import { SingleBookingDates } from '../common'

const AngelBookingDetailsScreen = () => {
  const { t } = useTranslation()
  const { getParam, navigate, goBack } = useRouter()
  const alert = useAlert()

  const obscured_id = getParam('bookingId') as string

  const [selectedDates, setSelectedDates] = useState<Omit<BookingDate, 'repeat_days'>[]>([])

  const { data: user } = useGetUserQuery()
  const { data: booking } = useGetBookingDetailsQuery({ obscured_id }, { skip: !obscured_id })
  const { data: cancellationReasons } = useGetBookingCancellationReasonsQuery(
    {
      booking_id: (booking as IBookingDetails)?.id.toString()
    },
    { skip: !booking }
  )
  const [accept, { isLoading: acceptLoading }] = useAcceptInvitationMutation()
  const [decline, { isLoading: declineLoading }] = useDeclineInvitationMutation()
  const [createOffer, { isLoading: createOfferLoading }] = useSendOfferMutation()

  const bookingBelongsToAngel = booking?.angel?.angel_user_id === user?.angel?.angel_user_id

  useEffect(() => {
    if (booking) {
      const bookingDates = flattenBookingDates(booking.booking_dates)
      const angelOffer = booking.offers.find(offer => offer.angel?.angel_user_id === user?.angel?.angel_user_id)

      if (angelOffer) {
        const selected = bookingDates.filter(
          date => !!angelOffer.offers.find(offer => offer.booking_obscured_id === date.obscured_id)
        )

        setSelectedDates(selected)
      } else {
        setSelectedDates(flattenBookingDates(booking.booking_dates))
      }
    }
  }, [booking, user])

  if (!booking || !user?.angel) {
    return <ScreenLayout isLoading title={t('booking')} />
  }

  const flattenDates = flattenBookingDates(booking.booking_dates)
  const invitation = booking.invitations.find(
    item => item.angel.angel_user_id === user.angel?.angel_user_id && InvitationsStates.PENDING === item.current_state
  )

  const onAccept = async () => {
    if (invitation && flattenDates.length === selectedDates.length) {
      try {
        // accept invitation
        await accept({
          bookingObscuredId: booking.obscured_id,
          invitationObscuredId: invitation.obscured_id
        }).unwrap()

        navigate('InviteAccepted', '/invite-accepted')
      } catch (error) {
        alert.show(t('error'), t('acceptInvitationError'))
      }
    } else {
      try {
        // create an offer
        await createOffer({
          booking_id: booking.obscured_id,
          selected_bookings: selectedDates.map(date => date.obscured_id),
          travel_allowance: {
            amount: 0,
            currency: 'EUR'
          },
          day_rate: booking.day_rate_per_hour,
          night_rate: booking.night_rate_per_hour,
          angel_message: ''
        }).unwrap()

        navigate('OfferCreated', '/offer-created')
      } catch (error) {
        alert.show(t('error'), t('createOfferError'))
      }
    }
  }

  const onDecline = async () => {
    try {
      if (!invitation) throw new Error('no invitation')

      await decline({
        bookingObscuredId: booking.obscured_id,
        invitationObscuredId: invitation?.obscured_id
      }).unwrap()

      goBack()
    } catch (error) {
      alert.show(t('error'), t('declineInvitationError'))
    }
  }

  return (
    <ScreenLayout
      title={t('booking')}
      subtitle={booking.obscured_id}
      BottomComponent={
        invitation ? (
          <HStack borderColor="gray.400" borderTopWidth={1} py="12px" px="20px" space="10px" w="100%" flexDir="row">
            <Center flex={1}>
              <Button isLoading={declineLoading} onPress={onDecline} _text={{ fontWeight: 600 }} variant="text">
                {t('decline')}
              </Button>
            </Center>
            <Button
              isDisabled={!selectedDates.length}
              isLoading={acceptLoading || createOfferLoading}
              onPress={onAccept}
              flex={1}
            >
              {selectedDates.length === flattenDates.length
                ? t('accept')
                : `${selectedDates.length}/${flattenBookingDates.length} | ${t('sendOffer')}`}
            </Button>
          </HStack>
        ) : undefined
      }
      _buttonDisclaimer={{
        children: t('addToCalendarDisclaimer')
      }}
      _buttonProps={
        booking.current_state === BookingStates.ACCEPTED && bookingBelongsToAngel
          ? {
              onPress: () =>
                saveInCalendar(
                  booking.start_date,
                  booking.end_date,
                  t('bookingCalendarTitle'),
                  t('bookingCalendarDesc')
                ),
              children: t('addToCalendar')
            }
          : undefined
      }
    >
      {booking.service_type &&
        [BookingServiceType.ELDERLY_CARE, BookingServiceType.PET_CARE, BookingServiceType.MEET_AND_GREET].includes(
          booking.service_type
        ) && <BookingServiceTypeInfo bookingServiceType={booking.service_type} />}

      <BookingFamilyDetails mt="10px" borderBottomWidth={0} family={{ ...booking, ...booking.family }} />

      <BookingRatesDetails
        mt="0"
        mb="0"
        borderTopWidth={0}
        borderBottomWidth={1}
        dayRate={booking.day_rate_per_hour}
        nightRate={booking.night_rate_per_hour}
        maxRate={booking.max_hourly_rate}
        expectedEarnings={booking.expected_earnings}
        travelAllowance={booking.travel_allowance}
      />

      {booking.personal_message !== '' && <BookingMessage mt="0" mb="0" borderTopWidth="0" booking={booking} />}

      {booking.current_state === BookingStates.ACCEPTED && bookingBelongsToAngel && (
        <Card borderTopWidth="0px" mt="0px" p="10px">
          <Button
            disabled={!cancellationReasons || cancellationReasons?.length === 0}
            ml="auto"
            width="50%"
            size="xs"
            colorScheme={cancellationReasons && cancellationReasons?.length > 0 ? 'primary' : 'gray'}
            variant="outline"
            onPress={() => {
              if (cancellationReasons && cancellationReasons.length > 0) {
                navigate('BookingCancellationReasons', '/booking-cancellation-reasons', {
                  bookingId: booking.obscured_id,
                  cancellationReasons: cancellationReasons
                })
              }
            }}
          >
            {t('bookingsScreenCancelBooking')}
          </Button>
        </Card>
      )}
      {isSingleBooking(booking) ||
      ![BookingStates.PENDING, BookingStates.PENDING_PAYMENT].includes(booking.current_state) ? (
        <SingleBookingDates startsAt={booking.start_date} endsAt={booking.end_date} />
      ) : (
        <BookingDates
          readonly={!invitation || isSingleBooking(booking)}
          bookings={
            [BookingStates.PENDING, BookingStates.PENDING_PAYMENT].includes(booking.current_state)
              ? booking.booking_dates
              : [
                  {
                    start_date: booking.start_date,
                    end_date: booking.end_date,
                    obscured_id: booking.obscured_id,
                    current_state: booking.current_state,
                    parent_obscured_id: '',
                    repeat_days: []
                  }
                ]
          }
          selectedDates={selectedDates}
          onChange={setSelectedDates}
        />
      )}

      <AddressDetails
        mt="10px"
        distance={booking.distance}
        lat={booking.family.lat}
        lon={booking.family.lon}
        street_name={booking.family.street_name}
        city={booking.family.city}
      />
    </ScreenLayout>
  )
}

AngelBookingDetailsScreen.navigationOptions = {
  headerShown: false
}

export default AngelBookingDetailsScreen
