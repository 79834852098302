import React, { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import { Box, Center, Text } from 'native-base'
import { SharedProviders } from '@charlycares/features'
import { isDev } from '@charlycares/shared'
import { webReducers } from '@charlycares/legacy/web'
import { Alert } from '@charlycares/ui'

import LegacyRouter from '../legacy/routes'

import 'rc-slider/assets/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'video-react-css'
import './index.css'

if (!isDev) {
  Sentry.init({
    dsn: process.env.NX_SENTRY_DSN_REACT,
    release: `react${process.env.npm_package_version}`
  })
}

function App() {
  return (
    <Sentry.ErrorBoundary fallback={() => <Center h="100dvh">An error ocurred</Center>}>
      <SharedProviders reducers={webReducers}>
        <Suspense fallback={null}>
          <LegacyRouter />
          <Alert />
        </Suspense>
      </SharedProviders>
    </Sentry.ErrorBoundary>
  )
}

export default App
