import { useCallback, useEffect, useState, useRef } from 'react'
import * as Sentry from '@sentry/react-native'
import { EmitterSubscription, Platform } from 'react-native'
import {
  requestSubscription,
  initConnection,
  purchaseUpdatedListener,
  purchaseErrorListener,
  Subscription,
  SubscriptionPurchase,
  finishTransaction,
  getSubscriptions,
  Purchase,
  endConnection,
  getPurchaseHistory
} from 'react-native-iap'

import { useCompleteAppleMembershipMutation } from '@charlycares/data-access'

const SKUS = [
  'com.charlycares.basic.monthly',
  'com.charlycares.basic.yearly',
  'com.charlycares.flexible.monthly',
  'com.charlycares.flexible.yearly',
  'com.charlycares.premium.monthly',
  'com.charlycares.premium.yearly',
  'com.charlycares.full_service.yearly',
  'com.charlycares.full_service.monthly',
  'com.charlycares.pet_service.monthly',
  'com.charlycares.pet_service.yearly'
]

export default function useMembershipIAP() {
  return

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [purchases, setPurchases] = useState<(Purchase | SubscriptionPurchase)[]>()
  const updateRef = useRef<EmitterSubscription>()
  const errorRef = useRef<EmitterSubscription>()

  const [completeTransactionIOS] = useCompleteAppleMembershipMutation()

  const init = useCallback(async () => {
    try {
      await initConnection()

      const subscriptions = await getSubscriptions({ skus: SKUS })
      const purchases = await getPurchaseHistory()

      setPurchases(purchases)
      setSubscriptions(subscriptions)

      updateRef.current = purchaseUpdatedListener(async purchase => {
        try {
          __DEV__ && console.log('purchaseUpdatedListener', purchase)
          const transactionReceipt = purchase.transactionReceipt

          if (!transactionReceipt) return
          await completeTransactionIOS({ transactionReceipt }).unwrap()
          await finishTransaction({ purchase })
        } catch (error) {
          __DEV__ && console.error('finishTransactionError', error)
        }
      })

      errorRef.current = purchaseErrorListener(error => {
        __DEV__ && console.error('purchaseErrorListener', error)
        Sentry.captureException(error)
      })
    } catch (error) {
      __DEV__ && console.log('IAP Connection Error', error)
      Sentry.captureException(error)
    }
  }, [completeTransactionIOS])

  useEffect(() => {
    if (Platform.OS !== 'ios') return

    init()

    return () => {
      updateRef.current?.remove()
      errorRef.current?.remove()
      updateRef.current = undefined
      errorRef.current = undefined

      endConnection()
    }
  }, [init])

  const subscribe = async (sku: string): Promise<void | SubscriptionPurchase | null> => {
    const subscription = await requestSubscription({
      sku: sku,
      andDangerouslyFinishTransactionAutomaticallyIOS: false
    })

    return subscription
  }

  return { subscribe, subscriptions, purchases }
}
