import { Linking } from 'react-native'
import { AngelRates, isWeb, AngelAvailability, isDev, AngelSummery } from '@charlycares/shared'
import {
  AngelReferenceReviewResponse,
  AngelReferenceReviewBody,
  AddAngelReferenceBody,
  AddAngelReferenceResponse,
  AngelTodo,
  AngelReference
} from './angels.dto'
import { api } from '../api'
import { userApi } from '../user'
import { ApiTags, ResponseData } from '../types'

export const angelsApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: ({ query, mutation }) => ({
    getAngelsAvgRates: query<AngelRates, void>({
      query: () => ({
        url: 'v2/angels/average-rates',
        method: 'GET'
      }),
      transformResponse: ({ data }): AngelRates => data
    }),
    angelReferenceReview: mutation<AngelReferenceReviewResponse, AngelReferenceReviewBody>({
      query: ({ angelReferenceId, ...body }) => ({
        url: `v2/angels/references/${angelReferenceId}/ratings`,
        method: 'POST',
        body
      })
    }),
    addAngelReference: mutation<AddAngelReferenceResponse, AddAngelReferenceBody>({
      query: body => ({
        url: 'v2/angels/references',
        method: 'POST',
        body
      }),
      invalidatesTags: [ApiTags.ANGEL_TODOS],
      onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled.then(() => dispatch(userApi.endpoints.getUserProfile.initiate())).catch(err => console.log(err))
      }
    }),
    getAngelReferences: query<AngelReference[], void>({
      query: () => ({
        url: 'v2/angels/references',
        method: 'GET'
      }),
      transformResponse: ({ data }): AngelReference[] => data
    }),
    setAngelAvailabilityTypes: mutation<void, { occasional: boolean; structural: boolean }>({
      query: body => ({
        url: `v2/angels/availability-type`,
        method: 'POST',
        body
      }),
      invalidatesTags: [ApiTags.ANGEL_TODOS],
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getUser', undefined, draft => {
            Object.assign(draft, { angel: { ...draft.angel, ...patch } })
          })
        )

        queryFulfilled.catch(patchResult.undo)
      }
    }),
    getAngelAvailabilities: query<AngelAvailability[], void>({
      query: () => ({
        url: `v2/angels/availabilities`,
        method: 'GET'
      }),
      transformResponse: ({ data }): AngelAvailability[] => data,
      providesTags: [ApiTags.AVAILABILITIES]
    }),
    updateAngelAvailabilities: mutation<void, { availabilities: AngelAvailability[] }>({
      query: body => ({
        url: `v2/angels/availabilities`,
        method: 'POST',
        body
      }),
      invalidatesTags: [ApiTags.AVAILABILITIES, ApiTags.ANGEL_TODOS, ApiTags.USER]
    }),
    uploadAngelPhoto: mutation<ResponseData<{ image: string | null }>, FormData>({
      query: body => ({
        url: 'v2/angels/photo',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        body
      }),
      onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then(({ data }) => {
            dispatch({
              type: isWeb ? 'set_user' : 'update_profile',
              payload: { image: data.data.image }
            })
          })
          .catch(err => isDev && console.warn(err))
      }
    }),
    uploadAngelVideo: mutation<{ video_url: string }, void>({
      query: body => ({
        url: 'v2/angels/video',
        method: 'POST',
        body
      }),
      transformResponse: ({ data }): { video_url: string } => data
    }),
    generateAngelCV: mutation<{ cv_url: string }, void>({
      query: () => ({
        url: `v2/angels/kluscv`,
        method: 'GET'
      }),
      onQueryStarted(arg, { queryFulfilled }) {
        queryFulfilled.then(({ data }) => Linking.openURL(data?.cv_url))
      }
    }),
    getAngelTodos: query<AngelTodo[], void>({
      query: () => ({
        url: `v2/angels/todos`,
        method: 'GET'
      }),
      providesTags: [ApiTags.ANGEL_TODOS],
      transformResponse: ({ data }): AngelTodo[] => data
    }),
    getAngelDetails: query<AngelSummery, { angelObscuredId?: string }>({
      query: ({ angelObscuredId }) => ({
        url: `v2/angels/details/${angelObscuredId}`,
        method: 'GET'
      }),
      transformResponse: ({ data }): AngelSummery => data,
      providesTags: (result, error, arg) => [{ type: ApiTags.ANGEL_DETAILS, id: arg.angelObscuredId }]
    }),
    linkFamily: mutation<ResponseData<void>, { family_code: string }>({
      query: body => ({
        url: `v2/angels/family/connect`,
        method: 'POST',
        body
      }),
      invalidatesTags: [ApiTags.USER],
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getUser', undefined, draft => {
            Object.assign(draft, { angel: { ...draft.angel, ...patch } })
          })
        )

        queryFulfilled.catch(patchResult.undo)
      }
    }),
    activateAngelAccount: mutation<void, { active: boolean }>({
      query: body => ({
        url: `v2/users/angels/active`,
        method: 'POST',
        body
      }),
      invalidatesTags: [ApiTags.USER],
      onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then(({ data }) => dispatch(userApi.endpoints.getUserProfile.initiate()))
          .catch(err => {
            console.log(err)
          })
      }
    })
  })
})

export const {
  useGetAngelsAvgRatesQuery,
  useAngelReferenceReviewMutation,
  useAddAngelReferenceMutation,
  useGetAngelReferencesQuery,
  useUploadAngelPhotoMutation,
  useGetAngelAvailabilitiesQuery,
  useUpdateAngelAvailabilitiesMutation,
  useSetAngelAvailabilityTypesMutation,
  useGenerateAngelCVMutation,
  useGetAngelTodosQuery,
  useUploadAngelVideoMutation,
  useGetAngelDetailsQuery,
  useLinkFamilyMutation,
  useActivateAngelAccountMutation
} = angelsApi
