import { Money } from './Money'
import { UserLanguages } from './User'

export interface Angel {
  id: number
  angel_user_id: string
  current_state: AngelState
  family_id?: number
  is_signup_test: boolean
  angel_references?: AngelReference[]
  is_family_angel: boolean
  screening_completed: boolean
  short_bio: string
  video?: string
  video_upload_url: string
  education: string
  field_of_study: string
  first_aid: boolean
  tutor: boolean
  driving_license: boolean
  normal_rate: number
  extra_rate: number
  min_age_children: number
  occasional: boolean
  structural: boolean
  available_points: number
  average_rating: number
  has_todo_items: boolean
  availabilities: AngelAvailability[]
  day_rate_per_hour: Money
  night_rate_per_hour: Money
  angel_appointment?: IAngelAppointment
  angelAppointment?: IAngelAppointmentOld
  verification_link: string
  is_verified: boolean
  image_quality_passed: boolean
}

export interface AngelSummery {
  id: number
  obscured_id: string
  angel_user_id: string
  first_name: string
  birthdate: string
  age: number
  image: string
  video?: string
  phone: string
  day_rate_per_hour: Money
  night_rate_per_hour: Money
  distance: number
  distance_in_km: number
  short_bio: string
  response_time: number
  education: string
  field_of_study: string
  babysit_expertise: boolean
  driving_license: boolean
  tutor: boolean
  first_aid: boolean
  liability_insurance: boolean
  pro: boolean
  babysit_experience: number
  booked_before: boolean
  is_chat_allowed: boolean
  conversation_id: string
  availabilities: AngelAvailability[]
  languages: UserLanguages
  average_rating: number
  is_liked: boolean
  last_seen_at: string
  min_age_children: number
  toddler_expertise: boolean
  works_with_kids: boolean
  written_review_count: number
  rank?: number
  is_favourite: boolean
  available: boolean
}

export interface IAngelAppointment {
  id: number
  current_state: AngelAppointmentStates
  timeslot: {
    available: boolean
    start_date: string
    end_date: string
  }
  agenda: {
    id: number
    description: string
    city: string
    address: string
    postalcode: string
    lat: number
    lon: number
    distance: number
    hours_in_advance: number
  }
  recruiter: {
    first_name: string
    image_url?: string
  }
}

export interface IAngelAppointmentOld {
  current_state: AngelAppointmentStates
  recruiter: {
    image_url: string
  }
  agenda: {
    description: string
  }
  timeslot: {
    start_date: string
    end_date: string
  }
}

export enum AngelAppointmentStates {
  ACCEPTED = 'accepted',
  CANCELED = 'canceled'
}

export type AngelRates = {
  normal_rate: Money
  extra_rate: Money
}

export enum AngelState {
  RETIRED = 'retired',
  SUSPENDED = 'suspended',
  COULD_BOOST = 'could_boost',
  PAUSED = 'paused',
  NOT_SCREENED = 'not_screened'
}

export interface AngelReference {
  id: number
  name: string
  email: string
  association_type: string
  phone: string
}

export enum AngelBabysittingExperience {
  NewBorn = 0,
  Baby = 6,
  Toddler = 12,
  SmallChild = 24,
  Child = 48
}

export interface AngelAvailability {
  id: number
  angel_id: number
  day_of_week: number
  morning: boolean
  afternoon: boolean
  evening: boolean
}

export enum AngelDailyAvailability {
  morning = 'morning',
  afternoon = 'afternoon',
  evening = 'evening'
}

export enum AngelAvailabilityType {
  structural = 'structural',
  occasional = 'occasional'
}

export enum AngelTodos {
  REFERENCE = 'references',
  BANK_ACCOUNT = 'bankAccount',
  HOURLY_RATES = 'hourlyRates',
  PREFERENCES = 'preferences',
  PROMO_VIDEO = 'promoVideo',
  SHORT_BIO = 'shortBio',
  PROFILE_DETAILS = 'profileDetails',
  PROFILE_VERIFIED = 'profileVerified',
  PROFILE_PICTURE = 'profilePhoto',
  PROFILE_PICTURE_VERIFIED = 'profilePhotoVerified'
}

/**
 * @deprecated
 */
export interface OldAngelsSummery {
  obscured_id: string
  id: number
  day_rate_per_hour: Money
  night_rate_per_hour: Money
  angel_user_id: string
  accept_ratio: number
  accepted_at: string
  age: number
  agenda_updated_at?: string
  angel_id: number
  availabilities: AngelAvailability[]
  available: number
  average_rating: number
  babysit_expertise: boolean
  birthdate: string
  booked_before: boolean
  conversation_id?: number
  created_at: string
  current_state: AngelState
  distance: number
  does_not_want_fixed: boolean
  driving_license: boolean
  education: string
  extra_rate: number
  field_of_study: string
  first_aid: boolean
  first_name: string
  fixed: boolean
  image?: string
  is_liked: boolean
  languages: UserLanguages
  last_booking_for_family_at?: string
  last_seen_at?: string
  liability_insurance: boolean
  min_age_children: number
  nationality: string
  new_angel: number
  normal_rate: number
  phone?: string
  response_time: number
  short_bio: string
  toddler_expertise: boolean
  tutor: boolean
  type: string
  user_id: number
  validated_profile: boolean
  video?: string
  video_confirmed: number
  vog: boolean
  works_with_kids: boolean
  written_review_count: number
  rank?: number
}
