import React, { useEffect, useState } from 'react'
import { Card, ScreenLayout } from '@charlycares/ui'
import { Box, Button, Center, Heading, HStack, Skeleton, Text } from 'native-base'
import { AngelSummery, BookingServiceType, flattenBookingDates, formatMoney, useRouter } from '@charlycares/shared'
import {
  useAcceptOfferMutation,
  useCalculatePotentialEarningsMutation,
  useDeclineOfferMutation,
  useGetAngelDetailsQuery,
  useGetBookingDetailsQuery
} from '@charlycares/data-access'
import { useTranslation } from 'react-i18next'

import { OfferDatesList } from '../common'
import { useAlert } from '../../../hooks'
import { isSingleBooking } from '../common/booking.utils'
import AngelCard from '../angel/common/AngelCard'
import SkeletonAngelCard from '../angel/common/SkeletonAngelCard'

const ViewOfferScreen = ({ navigation }: any) => {
  const { t } = useTranslation()
  const { getParam, navigate, popToTop } = useRouter(navigation)
  const alert = useAlert()

  const bookingId = getParam('bookingId') as string
  const offerId = getParam('offerId') as string

  const [bookingRate, setBookingRate] = useState(0)

  const [decline, declineState] = useDeclineOfferMutation()
  const [accept, acceptResult] = useAcceptOfferMutation()

  const { data: booking } = useGetBookingDetailsQuery({ obscured_id: bookingId })
  const offer = booking?.offers.find(item => item.obscured_id === offerId)
  const isRateAdjusted = offer?.day_rate_per_hour.amount !== offer?.angel.day_rate_per_hour.amount

  const [calculatePotentialEarnings, { isLoading: isLoadingBookingRate }] = useCalculatePotentialEarningsMutation()
  const { data: angel } = useGetAngelDetailsQuery(
    { angelObscuredId: offer?.angel.obscured_id },
    {
      skip: !offer?.angel.obscured_id
    }
  )

  useEffect(() => {
    if (!booking || !offer) return

    if (booking?.service_type === BookingServiceType.PET_CARE) {
      calculatePotentialEarnings({
        booking_obscured_id: booking.obscured_id,
        travel_allowance: {
          amount: booking?.travel_allowance.amount,
          currency: 'EUR'
        },
        day_rate: {
          amount: offer.day_rate_per_hour.amount,
          currency: 'EUR'
        },
        night_rate: {
          amount: offer.night_rate_per_hour.amount,
          currency: 'EUR'
        }
      })
        .unwrap()
        .then(data => {
          setBookingRate(data.amount)
        })
    } else {
      setBookingRate(offer.day_rate_per_hour.amount)
    }
  }, [booking, calculatePotentialEarnings])

  useEffect(() => {
    if (acceptResult.isSuccess) {
      navigate('OfferAccepted', '/offer-accepted', { offer: acceptResult.data })
    }
  }, [acceptResult.isSuccess, navigate, offer])

  useEffect(() => {
    if (acceptResult.isError) {
      alert.show(t('error'), t('acceptOfferError'))
    }
  }, [acceptResult.isError, t, alert])

  useEffect(() => {
    if (declineState.isError) {
      alert.show(t('error'), t('declineOfferError'))
    }
  }, [declineState.isError, t, alert])

  if (!booking || !offer) {
    return <ScreenLayout isLoading title={t('offer')} />
  }

  const onDecline = () => {
    alert.show(t('declineOfferTitle'), t('declineOfferMessage'), [
      { text: t('cancel') },
      {
        text: t('confirm'),
        onPress: async () => {
          await decline({
            parentBookingObscuredId: offer.parent_booking_obscured_id,
            offerObscuredId: offer.obscured_id
          }).unwrap()
          popToTop()
        }
      }
    ])
  }

  return (
    <ScreenLayout
      title={t('offer')}
      subtitle={offer.obscured_id}
      BottomComponent={
        <HStack borderColor="gray.400" borderTopWidth={1} py="12px" px="20px" space="10px" w="100%" flexDir="row">
          <Center flex={1}>
            <Button
              onPress={onDecline}
              isLoading={declineState.isLoading}
              _text={{ color: 'gray.600', fontWeight: 600 }}
              variant="text"
            >
              {t('decline')}
            </Button>
          </Center>
          <Button
            isLoading={acceptResult.isLoading}
            onPress={() => {
              accept({
                parentBookingObscuredId: offer.parent_booking_obscured_id,
                offerObscuredId: offer.obscured_id
              })
            }}
            flex={1}
          >
            {isSingleBooking(booking)
              ? t('accept')
              : `${t('accept')} ${offer.offers.length}/${flattenBookingDates(booking.booking_dates).length}`}
          </Button>
        </HStack>
      }
    >
      {angel ? (
        <AngelCard
          angel={angel as AngelSummery}
          isSelectableInDetails={false}
          bookingServiceType={booking.service_type}
        />
      ) : (
        <SkeletonAngelCard />
      )}

      {isRateAdjusted && (
        <Card>
          <Box flexDir="row" justifyContent="space-between">
            <Heading size="md" flex={1}>
              {t('adjustedRate')}
            </Heading>
            <Box>
              {isLoadingBookingRate ? (
                <Center>
                  <Skeleton h="25px" w="60px" rounded="full" startColor="gray.400" />
                  <Skeleton mt="5px" h="3" w="70px" rounded="full" startColor="gray.400" />
                </Center>
              ) : (
                <>
                  <Text fontSize="16px" fontWeight="600" color="alert.danger.400">
                    {formatMoney({
                      amount: bookingRate,
                      currency: offer?.day_rate_per_hour.currency
                    })}
                  </Text>
                  <Text fontSize="12px" color="alert.danger.400">
                    {booking?.service_type === BookingServiceType.PET_CARE ? t('perBooking') : t('perHour')}
                  </Text>
                </>
              )}
            </Box>
          </Box>

          <Text fontSize="15px" mt="10px" color="gray.800">
            {t('adjustedRateDescription')}
          </Text>
        </Card>
      )}

      {!!offer?.travel_allowance.amount && (
        <Card>
          <Box flexDir="row" justifyContent="space-between">
            <Heading size="md" flex={1}>
              {t('travelCompensation')}
            </Heading>
            <Box>
              <Text fontSize="16px" fontWeight="600" color="alert.danger.400">
                + {formatMoney(offer?.travel_allowance)}
              </Text>
              <Text fontSize="12px" color="alert.danger.400">
                {t('perBooking')}
              </Text>
            </Box>
          </Box>
        </Card>
      )}

      {!!offer?.angel_message && (
        <Card>
          <Heading size="md" flex={1}>
            {t('messageFromAngel')}
          </Heading>
          <Text fontSize="15px" mt="10px" color="gray.800">
            {offer.angel_message}
          </Text>
        </Card>
      )}

      <OfferDatesList bookings={booking.booking_dates} offer={offer} />
    </ScreenLayout>
  )
}

export default ViewOfferScreen

ViewOfferScreen.navigationOptions = {
  headerShown: false
}
