import React, { useState } from 'react'
import { Button, HStack, Input, ScrollView, Spacer, VStack } from 'native-base'
import { CharlyIcon } from '@charlycares/ui'
import { ChatContact } from '@charlycares/data-access'
import { useTranslation } from 'react-i18next'
import { isIos, makeCall, useRouter, UserRoles } from '@charlycares/shared'

interface Props {
  contact: ChatContact
  sendMessage: (message: string) => void
}

export default function ChatToolbar({ contact, sendMessage }: Props) {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const [newMessage, setNewMessage] = useState<string>('')
  const [height, setHeight] = useState(0)
  const addExtra = isIos ? 25 : 0

  return (
    <VStack bgColor="white" px="10px" position="relative">
      <Input
        position="absolute"
        variant="unstyled"
        bgColor="white"
        value={newMessage}
        multiline
        px="0"
        placeholder={t('chatScreen.writeMessage')}
        onChangeText={text => setNewMessage(text)}
        onContentSizeChange={event => setHeight(event.nativeEvent.contentSize.height + addExtra)}
        height={Math.max(35, newMessage.length > 0 ? height : 0)}
        roundedTop="md"
        left="10px"
        width="100%"
        bottom="60px"
        zIndex="10"
      />
      <HStack height="50px" space="5" mt="60px">
        <CharlyIcon
          name="icn-calendar-add"
          size="28px"
          color="gray.800"
          onPress={() => {
            const params =
              contact.role === UserRoles.FAMILY ? { familyId: contact.obscured_id } : { angelId: contact.obscured_id }
            navigate('BookDirect', '/book-direct', params)
          }}
        />
        <CharlyIcon
          name="icn-telephone"
          size="28px"
          color="gray.800"
          onPress={() => {
            makeCall(contact.phone)
          }}
        />
        <Spacer />
        <Button
          height="28px"
          py="0px"
          bgColor={newMessage ? 'secondary.400' : 'white'}
          colorScheme={newMessage ? 'filled' : 'gray'}
          color="white"
          variant={newMessage ? 'solid' : 'outline'}
          onPress={() => {
            sendMessage(newMessage)
            setNewMessage('')
          }}
          fontSize="16px"
        >
          {t('chatScreen.send', 'Send')}
        </Button>
      </HStack>
    </VStack>
  )
}
