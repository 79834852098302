import React from 'react'
import { BookingServiceType, BookingStates, IBookingDetails, useRouter, UserRoles } from '@charlycares/shared'
import { Avatar, Badge, Box, Button, Heading, HStack, IPressableProps, Pressable, Text } from 'native-base'
import { useGetUserQuery } from '@charlycares/data-access'
import { CharlyIcon, CountDownTimer } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'

import useBookingState from './useBookingState'
import {
  getAngelInvite,
  getArchivedInvitations,
  getInfoFromBookingServiceType,
  getPendingOffers,
  isBookingArchived
} from '../common'
import { BookingCardDates } from '../../common'

type Props = IPressableProps & {
  booking: IBookingDetails
}

const BookingCard = ({ booking, ...boxProps }: Props) => {
  const { angel, family, booking_dates, invitations, start_date, end_date } = booking

  const { t } = useTranslation()
  const { navigate } = useRouter()

  const { data: user } = useGetUserQuery()
  const { message, action, color } = useBookingState(booking)

  const angelInvite = getAngelInvite(booking)
  const archivedInvitations = getArchivedInvitations(booking)
  const familyOffers = getPendingOffers(booking)
  const avatar = user?.role === UserRoles.ANGEL ? family?.image : angel?.image
  const { icon: iconName, icon_color: iconColor } = getInfoFromBookingServiceType(booking.service_type)

  const getFamilyBookingTitle = () => {
    if (angel) {
      return `${angel.first_name} (${angel.age})`
    } else if (isBookingArchived(booking)) {
      return ''
    } else if (familyOffers.length) {
      return t('angel_offers')
    } else if (!booking.invitations_allowed) {
      return t('bookingCard.title.waitingForAngels')
    } else if (!invitations.length) {
      return t('no_angels_invited')
    } else if (archivedInvitations.length === invitations.length) {
      return t('all_angels_declined')
    } else {
      return t('no_matches_yet')
    }
  }

  return (
    <Pressable
      _pressed={{
        opacity: 0.5
      }}
      w="100%"
      p="15px"
      bg="white"
      borderTopWidth={0.5}
      borderBottomWidth={0.5}
      borderColor="gray.200"
      {...boxProps}
    >
      <Box flexDir="row" w="100%" flex={1}>
        <HStack mr="10px" flex={1} alignItems="center" space="10px">
          <Box>
            {avatar ? (
              <Avatar borderWidth={1} borderColor="gray.400" size="40px" source={{ uri: avatar }} />
            ) : (
              <CharlyIcon name="avatar" size={40} />
            )}

            {user?.family && !!familyOffers.length && (
              <Badge position="absolute" right="-5px" top="-5px">
                {familyOffers.length > 9 ? `${familyOffers.length}+` : familyOffers.length}
              </Badge>
            )}
          </Box>

          <Heading flex={1} fontSize="16px" fontWeight="600" numberOfLines={1} ellipsizeMode="tail">
            {user?.role === UserRoles.ANGEL ? family.name : getFamilyBookingTitle()}
          </Heading>
        </HStack>

        <HStack space="4px" justifyContent="flex-end" alignItems="center">
          <Text fontSize="12px">{t(message)}</Text>
          <Box h="12px" w="12px" borderRadius="30px" bg={color} />
        </HStack>
      </Box>

      <Box flexDir="row" alignItems="flex-end">
        <BookingCardDates bookingDates={booking_dates} startsAt={start_date} endsAt={end_date} />

        <Box alignItems="flex-end">
          {user?.role === UserRoles.ANGEL && !!angelInvite?.expires_at && (
            <CountDownTimer date={angelInvite.expires_at} />
          )}

          {action ? (
            <Button
              mt="10px"
              onPress={() =>
                user?.family?.membership || user?.family?.has_canceled_membership
                  ? navigate('MembershipInfoScreen', '/profile-membership')
                  : navigate('BookingIsAlmostSentScreen', '/booking-is-almost-sent')
              }
              disabled={!(user?.role === UserRoles.FAMILY && booking.current_state === BookingStates.PENDING_PAYMENT)}
              size="xs"
              h="30px"
              variant="outline"
              _text={{
                fontSize: '13px',
                fontWeight: 600
              }}
              leftIcon={
                booking.service_type && booking.service_type !== BookingServiceType.CHILD_CARE_SHORT_TERM ? (
                  <CharlyIcon size={20} color={`${iconColor}.400`} name={iconName} />
                ) : undefined
              }
            >
              {t(action)}
            </Button>
          ) : (
            booking.service_type &&
            booking.service_type !== BookingServiceType.CHILD_CARE_SHORT_TERM && (
              <CharlyIcon mr="5px" size={22} color={`${iconColor}.400`} name={iconName} />
            )
          )}
        </Box>
      </Box>
    </Pressable>
  )
}

export default BookingCard
